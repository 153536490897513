.header {

  &__brand {
    pointer-events: all;

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem 1rem;
      background-color: $color-shades-white;
      cursor: pointer;
      @include DL {
        padding: 0.75rem 1.5rem;
      }
      
      svg {
        width: 6.5rem;
        height: 1.5rem;
      }
    }

    &-dropdown {
      display: none;
      cursor: pointer;
      background-color: $color-hues-green;
      width: 12rem;
      height: 3rem;
      flex-wrap: wrap;
      @include DL {
        display: flex;
      }

      &__header {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;
        padding: 0 1rem;
      }

      &__list {
        padding: 0;
        margin: 0;
        width: 100%;

        li {
          list-style-type: none;

          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $font-family-primary;
            font-weight: $font-weight-semi-bold;
            background-color: white;
            font-size: 1rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            color: $color-shades-black;
            padding: 1rem;
            border: 0;
            border-bottom: 1px solid $color-shades-lightGray;
            width: 100%;
            text-align: left;

            svg {
              display: none;
              height: 1rem;
              width: 1rem;
              margin: 0;
            }

            &:hover,
            &:focus {
              cursor: pointer;
              border-bottom: 1px solid $color-shades-lightGray;
              background-color: $color-shades-lightGray;

              svg {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  &__actions {
    pointer-events: all;

    button {
      cursor: pointer;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-shades-white;

      svg {
        & + svg {
          margin-left: 0.25rem;
        }
      }
    }

    &-search,
    &-dropdown {
      background-color: $color-shades-white;
    }

    &-search {
      form {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        label {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            cursor: pointer;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0.75rem 1rem;
          }

          input {
            appearance: none;
            all: unset;
            font-family: $font-family-secondary;
            font-weight: $font-weight-regular;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $color-shades-lightBlack;
            width: 0px;
            height: 3rem;
            caret-color: transparent;
            @include TP {
              transition: .2s;
            }

            &::placeholder {
              visibility: hidden;
            }

            & + ul + button {
              display: none;
              transition: .2s;
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }

      &.active {
        input {
          caret-color: $color-shades-black;
          width: calc(100vw - 10rem);
          @include TP {
            width: 20rem;
          }

          @include DS {
            width: 25rem;
          }

          &::placeholder {
            visibility: visible;
          }

          & + ul + button {
            display: contents;
          }
        }
      }

      &__list {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        top: 3rem;
        background-color: $color-shades-white;

        button {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 0.5rem 1rem;
          background-color: #fff;
          text-decoration: none;
          width: 100%;
          text-align: inherit;
          font-family: $font-family-secondary;
          color: $color-shades-black;
          font-weight: $font-weight-regular;
          font-size: 0.875rem;
          line-height: 1rem;

          & > svg {
            height: 1rem !important;
            width: 1rem !important;
            margin: 0 !important;
            visibility: hidden;
          }

          &:hover {
            background-color: $color-shades-lightGray;

            & > svg {
              visibility: visible;
            }
          }
        }
      }
    }
    &-dropdown {
      display: none !important;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
      @include DL {
        margin-left: 2px;
        display: flex !important;
        width: 5rem;
        height: 3rem;
      }
    }

    &__dropdown-menu {
      padding: 1rem;

      @include TL {
        position: absolute;
        right: 0;
        top: 100%;
        margin-top: 1rem;
        width: 20rem;
        background: $color-shades-white;
        border-radius: 0.25rem;
        filter: drop-shadow(0 0.25rem 1rem transparentize($color-shades-black, 0.8));

        &::before {
          position: absolute;
          content: "";
          width: 0;
          right: 2rem;
          top: -1rem;
          height: 0;
          border-left: 1rem solid transparent;
          border-right: 1rem solid transparent;
          border-bottom: 1rem solid transparentize($color-shades-white, 0.1);
          border-top: 0.5rem solid transparent;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        width: 100%;

        hr {
          width: 100%;
        }

        li {
          & + li {
            margin-top: 0.5rem;
          }

          a {
            font-family: $font-family-secondary;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $color-shades-black;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            position: relative;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }

    &-mobile {
      background-color: $color-hues-green;
      width: 3rem;
      height: 3rem;
      margin-left: 0.5rem;

      @include DL {
        display: none !important;
      }
    }
  }

  &__mobile-menu {
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.35s, visibility 0.35s, height 0.35s;
      overflow: hidden;
      background: $color-shades-black;
      z-index: 1;

      &.active {
        visibility: visible;
        opacity: 0.4;
      }
    }
    &__sidebar,
    &__search {
      visibility: hidden;
      position: fixed;
      top: 0;
      height: 100%;
      background-color: $color-shades-white;
      transition: 0.3s;
      padding: 1.5rem;
      box-sizing: border-box;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      right: -20rem;
      width: 20rem;
      transition: all 0.5s ease;
      z-index: 999;

      &.active {
        visibility: visible;
        right: 0;
      }

      .header__mobile-menu__search-services.header__actions-search.active {
        margin-top: 0;
      }

      &-close {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        button {
          border: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          background: $color-shades-lightGray;
          cursor: pointer;
          
          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }

      &-services {
        margin-top: 1.5rem;
        
        ul {
          li {
            button {
              position: relative;
              border: 0;
              background-color: transparent;
              font-family: $font-family-primary;
              font-weight: $font-weight-regular;
              font-size: 1rem;
              line-height: 1.5rem;
              text-transform: uppercase;
              color: $color-shades-black;
              padding: 0;
              cursor: pointer;

              &.active {
                font-weight: $font-weight-semi-bold;

                &::before {
                  background-color: $color-hues-green;
                  bottom: -0.25rem;
                  content: "";
                  height: 0.25rem;
                  left: 0;
                  position: absolute;
                  width: 100%;
                }
              }
            }

            & + li {
              margin-top: 1.5rem;
            }
          }
        }
      }
      
      &-links {
        ul {
          li {
            a {
              position: relative;
              border: 0;
              background-color: transparent;
              font-family: $font-family-primary;
              font-weight: $font-weight-regular;
              font-size: 1rem;
              line-height: 1.5rem;
              text-transform: uppercase;
              color: $color-shades-black;
              padding: 0;
              cursor: pointer;
              font-weight: 400;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            & + li {
              margin-top: 0.5rem;
            }

            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
    }
    
    &__search {
      transition: all 0.5s ease;
      right: -100%;
      width: 100%;

      .header__actions-search {
        form {
          margin: auto;
          width: 20rem;

          label {
            width: 100%;
            background-color: $color-shades-white;
            border-bottom: 1px solid $color-shades-lightGray;

            & > svg {
              display: none;
            }

            & > button {
              width: 1.5rem;
              height: 1.5rem;
              margin: 0;
              border: 0;
              background-color: transparent;
              padding: 0;

              svg {
                display: flex;
              }
            }
            svg {
              width: 1.5rem;
              height: 1.5rem;
              margin: 0;
            }
            input {
              width: 100%;
              margin-left: 0.5rem;
            }
          }
        }
        &__list {
          margin-top: 0.25rem;
          border-top: 0 !important;
          button {
            padding: 0.5rem 1rem;
            font-family: $font-family-secondary;
            font-weight: $font-weight-regular;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $color-shades-black;
            border: none;
            & + button {
              border-top: none;
            }
          }
        }
      }
    }
  }
}