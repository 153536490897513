@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;600&family=IBM+Plex+Sans:wght@400;500;600&display=swap");


@function fontSize($type, $device: "desktop") {
  @return map-get(map-get($font-sizes, $type), $device);
}

$font-sizes: (
  h1: (
    mobile: 2rem,
    tablet: 3rem,
  ),
  h2: (
    mobile: 1.5rem,
    tablet: 2rem,
  ),
  h3: (
    mobile: 1.5rem,
    desktop: 1.625rem,
  ),
  h4: (
    mobile: 1.125rem,
  ),
  h5: (
    mobile: 1rem,
  ),
  body: (
    mobile: 1rem,
  ),
  span: (
    mobile: 0.875rem,
  ),
  small: (
    mobile: 1rem,
  ),
);

// GLOBAL FONT STYLES
h1 {
  font-family: $font-family-primary;
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: #{fontSize(h1, mobile)};
  @include TP {
    font-size: #{fontSize(h1, tablet)};
  }
}

h2 {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: #{fontSize(h2, mobile)};
  @include TP {
    font-size: #{fontSize(h2, tablet)};
  }
}

h3 {
  font-family: $font-family-secondary;
  font-weight: $font-weight-regular;
  line-height: 1.2;
  font-size: #{fontSize(h3, mobile)};
  @include DS {
    font-size: fontSize(h3, desktop);
  }
}

h4 {
  font-family: $font-family-primary;
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: fontSize(h4, mobile);
}

h5 {
  font-family: $font-family-secondary;
  font-weight: $font-weight-semi-bold;
  line-height: 1.2;
  font-size: fontSize(h5, mobile);
}

.body {
  font-family: $font-family-secondary;
  font-weight: $font-weight-regular;
  line-height: 1.4;
  text-transform: none;
}

p {
  @extend .body;

  &.bold {
    font-weight: $font-weight-medium;
  }

  &.small {
    font-size: fontSize(small, mobile);
  }
}

.span {
  font-family: $font-family-secondary;
  font-weight: $font-weight-regular;
  line-height: 1.2;
  font-size: fontSize(span, mobile);

  &--bold {
    font-weight: $font-weight-semi-bold;
  }
}

span {
  @extend .span;
}