.toolbar {
    background-color: $color-shades-black;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 1.5rem;
    margin: auto;
    width: fit-content;
    border-radius: 0.5rem;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    gap: 0.25rem;
  
    button {
      padding: 0.5rem 1rem;
      border-radius: 2px;
      width: 3.5rem;
      height: 2.5rem;
  
      &.active {
        background-color: $color-hues-green;
        svg {
          fill: $color-shades-black !important;
        }
      }
      &.close {
        margin-left: 0.375rem;
        &:before {
          content: "";
          display: block;
          width: 2px;
          height: 2rem;
          background: $color-shades-lightBlack;
          border-radius: 1px;
          right: 3.95rem;
          top: 0.5rem;
          position: absolute;
        }
      }
  
      svg {
        width: 24px;
        height: 24px;
        fill: $color-shades-white;
      }
    }
  }

  
  