// Default style

.content {
  width: 100%;
  height: calc(var(--vh) * 100);
  overflow: hidden;

  &__left {
    width: 100%;
    height: 100%;
    position: relative;

    &-top,
    &-bottom {
      width: 100%;
      left: 0;
      position: absolute;
      transition: .5s;
      pointer-events: none;
      touch-action: none;
      z-index: 99;
    }

    &-top {
      z-index: 1;
      top: 0;
      padding: 1rem 1.5rem;
      
      .--row {
        display: flex;
        justify-content: space-between;
        position: relative;
      }

      &.searching {
        .header__brand,
        .header__actions-mobile {
          display: none;
          @include TP {
            display: flex;
          }
        }
      }
    }

    &-bottom {
      bottom: 0;
      padding: 0 1.5rem;

      .--row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      &__left {
        &-map-container {
          position: relative;
        }
        
        &-legal {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color-shades-white;
          padding: 0.25rem 0.5rem;
          user-select: none;
          pointer-events: all;

          span,
          a {
            font-family: $font-family-secondary;
            font-size: 0.65rem;
            line-height: 0.5rem;
            color: $color-shades-black;
          }

          span {
            font-weight: $font-weight-regular;
          }

          a {
            font-weight: $font-weight-medium;
          }

          hr {
            margin: 0 0.25rem;
            border: 1px solid $color-shades-lightGray;
            height: 0.5rem;
          }
        }
      }
      &__right {
        padding-bottom: 1.5rem;
        pointer-events: all;

        button {
          display: flex;
          background-color: $color-shades-white;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 2rem;
          height: 2rem;
          border: none;
          cursor: pointer;
        }
        div {
          display: none;
          margin-top: 0.5rem;
          @include DL {
            display: grid;
          }
        }
        hr {
          margin: 0;
          border: 2px solid $color-shades-lightGray;
        }
      }
    }
  }

  &__right {
    margin-right: -45rem;
    transition: all 0.5s ease;
    width: 100%;
    height: calc(var(--vh) * 80);

    @include TP {
      height: calc(var(--vh) * 75);
    }

    @include TL {
      height: calc(var(--vh) * 100);
      width: 45rem;
    }
  }
}

// Active sidebar style

.content.sidebar-active {
  .content__left {
    width: 100%;
    height: calc(var(--vh) * 20);

    @include TP {
      height: calc(var(--vh) * 25);
    }

    @include TL {
      height: calc(var(--vh) * 100);
      width: calc(100% - 45rem);
    }

    &-bottom {
      display: none;

      @include TP {
        display: block;
      }
    }
  }

  .content__right {
    margin-right: 0;
  }
}
