html {
  font-size: 14px;
  @include DS {
    font-size: 16px;
  }
}

body {
  font-family: $font-family-primary;
  color: $color-shades-black;
  background-color: $color-shades-white;
  font-display: swap;
  overflow-x: hidden;

  &.fixed {
    overflow: hidden;
    height: calc(var(--vh) * 100);
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

hr {
  border: 1px solid $color-shades-lightGray;
}

.App {
  min-height: calc(var(--vh) * 100);
}
