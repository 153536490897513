.dimension-inputs {
  margin-top: 0.75rem;
  display: grid;
  grid-template-columns: 11.625rem 0.5625rem 11.625rem;
  gap: 0.5rem;
  z-index: 2;

  label {
    color: $color-shades-black;
    font-family: $font-family-primary;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.875rem;
    padding-left: 0.4375rem;
  }
  input {
    border-radius: 0.25rem;
    border: 1px solid $color-shades-black;
    background: white;
    width: 11.5938rem;
    height: 2.25rem;
    margin-top: 0.25rem;
    padding: 0.4375rem 0.6875rem;
  }

  &__separator {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 0.575rem;

    span {
      font-family: $font-family-secondary;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.1rem;
    }
  }
}
