button {
	cursor: pointer;
	display: flex;
	padding: 0;
	background-color: transparent;
	border: none;

	&.button__close {
		padding: 0.5rem;
		border-radius: 50%;
		background-color: $color-shades-lightGray;
		border: 0.125rem solid $color-shades-lightGray;
		@include DS {
			background-color: $color-shades-white;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}

		&:hover {
			background-color: $color-shades-lightGray;
		}

		&:active {
			background-color: $color-shades-black;

			svg > path {
				fill: $color-shades-white;
			}
		}
	}

	&.button__cta {
		flex-direction: row;
		width: 100%;
		gap: 0.125rem;
	
		& > div {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $color-hues-green;
			border: 1px solid $color-shades-black;
		}

		&.secondary > div {
			background-color: $color-shades-lightGray;
		}

		&.icon-after {
			flex-direction: row-reverse;
		}
	
		.text {
			flex: 1;
			width: 100%;
			padding: 0.5rem;
			font-family: $font-family-primary;
			font-weight: $font-weight-semi-bold;
			text-transform: uppercase;
			font-size: 0.875rem;
			line-height: 1.5rem;
			color: $color-shades-black;
		}
	
		.icon {
			flex-shrink: 0;
			padding: 0.5rem;
	
			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		&:hover {
			& > div {
				background-color: $color-shades-white;
				box-shadow: 0 0.25rem 0 transparentize($color-shades-black, 0.8);
			}
		}
	}
}