.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $color-shades-white;
  padding: 1.5rem;
  overflow-y: auto;
  gap: 1.5rem;
  @include TL {
    padding: 1.5rem 2rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $color-shades-lightGray;
    gap: 1rem;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__tags {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }

    &__coordinates {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      border: 1px solid $color-shades-darkGray;
      border-radius: 0.25rem;
      cursor: pointer;

      .span--bold {
        border-right: 1px solid $color-shades-darkGray;
        padding-right: 0.5rem;
      }

      svg {
        display: none;
        width: 1rem;
        height: 1rem;
      }

      &:hover {
        background-color: $color-shades-lightGray;

        svg {
          display: block;
        }
      }
    }

    &__surveyDate {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      border: 1px solid $color-shades-darkGray;
      border-radius: 0.25rem;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: fit-content;
    gap: 4rem;

    &-box {
      &__status {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        @include TP {
          padding: 0 4rem;
        }
        @include DS {
          padding: 0 5rem;
        }

        &-icon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 6rem;
          height: 6rem;

          &.Accepted {
            background-color: $color-hues-green;
          }
          &.Denied {
            background-color: $color-flag-notify;
          }
          &.Pending {
            background-color: $color-hues-yellow;
          }
          &.trial-ended {
            background-color: $color-shades-lightGray;
          }

          svg {
            width: 4rem;
            height: 4rem;
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 0.5rem;

          &__comment {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: 0.5rem;
            border: 2px solid $color-shades-lightGray;
            text-align: center;
            overflow: hidden;

            &-message {
              padding: 1rem;
            }

            &-contact {
              width: 100%;
              padding: 0.5rem;
              justify-content: center;
              font-family: $font-family-primary;
              font-weight: $font-weight-semi-bold;
              text-transform: uppercase;
              font-size: 0.875rem;
              line-height: 1.5rem;
              color: $color-shades-black;
              border-top: 2px solid $color-shades-lightGray;
              background-color: $color-shades-white;

              &:hover {
                background-color: $color-shades-lightGray;
              }
            }
          }
        }
      }

      &__request {
        width: 100%;
        @include TP {
          padding: 0 4rem;
        }
        @include DS {
          padding: 0 5rem;
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }

        &__head {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &.solar {
            margin-top: 0.5rem;
          }

          p {
            flex: 1;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        &__switch {
          &-buttons {
            display: flex;
            border: 1px solid $color-shades-lightGray;
            border-radius: 0.25rem;
            padding: 0.25rem;
            gap: 0.25rem;

            input {
              position: absolute !important;
              clip: rect(0, 0, 0, 0);
              height: 1px;
              width: 1px;
              border: 0;
              overflow: hidden;
            }

            label {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 50%;
              min-height: 3rem;
              padding: 0;
              color: $color-shades-lightBlack;
            }

            label:hover {
              cursor: pointer;
            }

            input:checked + label {
              background-color: $color-shades-lightGray;
              border: 1px solid $color-shades-black;
              border-radius: 0.125rem;
              color: $color-shades-black;
            }
          }
        }

        &__checkbox {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          &-buttons {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &.solar {
              .checkbox-wrapper {
                border: 0;
                &.active {
                  border: 1px solid;
                  background-color: $color-shades-lightGray;
                  border-color: $color-shades-black;
                  color: $color-shades-black;
                }
              }
            }

            .checkbox-wrapper {
              position: relative;
              border: 1px solid;
              border-radius: 0.125rem;
              border-color: $color-shades-lightGray;

              &.align-top {
                .checkbox-content {
                  align-items: flex-start;
                }
              }

              label {
                cursor: pointer;
              }

              .checkbox-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.5rem 0.75rem;
                min-height: 3rem;
                color: $color-shades-lightBlack;
                gap: 1rem;

                &__icon {
                  position: relative;
                  height: 1.5rem;
                  width: 1.5rem;
                  border-radius: 1rem;
                  border: 2px solid;
                  border-color: $color-shades-lightGray;
                  background-color: $color-shades-white;
                }

                &__label {
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 0.5rem;
                  z-index: 2;

                  h5 {
                    flex: 1;
                    text-align: left;
                  }
                  h6 {
                    width: 6rem;
                    text-align: right;
                    color: $color-shades-black;
                  }
                }
              }

              &.active {
                background-color: $color-shades-lightGray;
                border-color: $color-shades-black;
                color: $color-shades-black;

                .checkbox-content {
                  &__icon {
                    border-color: $color-shades-black;

                    &::after {
                      content: "";
                      display: block;
                      position: absolute;
                      top: calc(0.35rem - 2px);
                      left: calc(0.35rem - 2px);
                      width: 0.8rem;
                      height: 0.8rem;
                      border-radius: 50%;
                      background-color: $color-shades-black;
                    }
                  }
                }
              }

              input[type="radio"] {
                position: absolute;
                top: 0;
                left: 0;
                appearance: none;
                width: 100%;
                height: 100%;
                cursor: pointer;
                z-index: 1;
              }
            }
          }
        }

        &__miniscule {
          text-align: center;
        }
      }

      &__viewer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;

        ul {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          li {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          }
        }

        &__model {
          display: flex;
          position: relative;
          border: 1px solid $color-shades-lightGray;
          border-radius: 0.25rem;
          overflow: hidden;
          height: 18rem;
          @include TP {
            height: calc((var(--vh) * 100) - 35rem);
          }
          @include TL {
            min-height: 25rem;
            height: calc((var(--vh) * 100) - 31rem);
          }

          &.empty {
            align-items: center;
            justify-content: center;
            background-color: $color-shades-lightGray;
            padding: 4rem;
            text-align: center;
          }

          &.fullscreen {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: calc(var(--vh) * 100);
            z-index: 99;
          }
        }

        &__meta {
          width: 100%;

          ul {
            flex-wrap: wrap;

            li {
              width: 50%;
              max-width: calc(50% - 1rem);

              @include TP {
                width: 25%;
                max-width: calc(25% - 0.75rem);
              }
            }
          }
        }

        &__qrcode {
          display: none;
        }

        &__actions {
          display: flex;
          gap: 0.5rem;

          a.pdf__download {
            width: 100%;
          }
        }

        &__footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          &__report {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $color-shades-black;
            gap: 0.25rem;

            svg {
              width: 1rem;
              height: 1rem;
            }
          }

          &__updated {
            display: flex;
            flex-direction: row;
            gap: 0.25rem;
          }
        }
      }
    }
  }
}
